<template>
  <div class="main-content">
    <nav class="breadcrumb">
      <ul class="breadcrumb__nav">
        <slot name="breadcrumb">
          <!-- <li class="breadcrumb__nav-item breadcrumb__nav-item--back"><img src="@/assets/images/icons/ico-chev-left--70L.svg"></li> -->
          <li class="breadcrumb__nav-item"></li>
        </slot>
      </ul>
    </nav>
    <header class="titles-header">
      <div class="titles-header__side">
        <p>
          <slot name="abstract"></slot>
        </p>
        <h2 class="titles-header__title">
          <slot name="title"></slot>
        </h2>
        <p class="titles-header__subtitle subtitle">
          <slot name="subtitle"></slot>
        </p>
      </div>
    </header>

    <!-- <section class="main-section"></section> -->
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseViewLayout'
}
</script>
