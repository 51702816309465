<template>
  <div class="question">
    <img class="question__icon" :src="myQuestion.kind.icon">
    <div class="question__content">
      <p class="question-info">{{myQuestion.stem}}</p>
      <ul class="question__options" v-if="myQuestion.alternatives.length">
        <li class="question__option" v-for="(alternative, index) in myQuestion.alternatives" :key="index">{{alternative}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import questionKinds from '@/utils/questionKinds'

export default {
  name: 'QuestionCard',
  props: ['question'],
  computed: {
    myQuestion () {
      const myQuestion = this.$utils.cloneObj(this.question)
      myQuestion.kind = questionKinds.es.find(kind => kind.id === myQuestion.kind)
      return myQuestion
    }
  }
}
</script>
