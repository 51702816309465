<template>
  <ckeditor :editor="editor" v-model="editorValue" :config="config"></ckeditor>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'

import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Link from '@ckeditor/ckeditor5-link/src/link'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
//import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter'
//import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat'
//import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
//import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import List from '@ckeditor/ckeditor5-list/src/list'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed'
//import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
//import Table from '@ckeditor/ckeditor5-table/src/table'
//import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation'
import Font from '@ckeditor/ckeditor5-font/src/font'

import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter'


export default {
  name: 'ActivityInstructionCkeditor',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  components: {
    ckeditor: CKEditor.component
  },
  computed: {
    editorValue: {
      get () { return this.modelValue },
      set (val) { this.$emit('update:modelValue', val) }
    }
  },
  data () {
    return {
      editor: ClassicEditor,
      config: {
        plugins: [
          Essentials,
          Bold,
          Italic,
          Underline,
          Link,
          Paragraph,
          Alignment,
          //Autoformat,
          //BlockQuote,
          Heading,
          Image,
          ImageResize,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          List,
          MediaEmbed,
          //PasteFromOffice,
          TextTransformation,
          Font,
          SimpleUploadAdapter
        ],

        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'fontColor', 'fontBackgroundColor',
            'imageUpload',
            'bulletedList',
            'numberedList',
            '|',
            'indent',
            'outdent',
            '|',
            'mediaEmbed',
            'undo',
            'redo',
            'link'
          ]
        },
        
        image: {
          styles: [
            'alignLeft', 'alignCenter', 'alignRight'
          ],
          toolbar: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            'imageTextAlternative'
          ]
        },

        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: `${process.env.VUE_APP_API_BASE_URL}/api/pictures`,

          // Enable the XMLHttpRequest.withCredentials property.
          //withCredentials: true,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            'X-CSRF-TOKEN': 'CSRF-Token',
            Authorization: this.$cookies.get('jwt')
          }
        },
        mediaEmbed: {
          previewsInData: true
        }
      }
    }
  }
}
</script>
