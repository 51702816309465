<template>
  <div class="form__group">
    <label class="form__label" :for="$.uid">
      <slot></slot>
    </label>
    <select v-if="type === 'select'" class="form__input" v-model="inputValue" :id="$.uid" :required="required" :autofocus="autofocus" :disabled="disabled">
      <option v-if="selectPrompt" :value="null" selected :disabled="required">{{selectPrompt}}</option>
      <option v-for="option in options" :key="option.id" :value="option.id">{{option.name}}</option>
    </select>
    <div v-else-if="type === 'radioGroup'" class="checkbox__group">
      <template v-for="option in groupOptions" :key="option.id">
        <input :id="`${$.uid}-${option.id}`" type="radio" :value="option.id" v-model="inputValue" :required="required">
        <label class="checkbox__label" :for="`${$.uid}-${option.id}`">
          <div v-if="option.icon" class="checkbox__indicator">
            <img :src="option.icon">
          </div>
          <div class="checkbox__box">
            <p class="caption--xs">{{option.name}}</p>
          </div>
        </label>
      </template>
    </div>
    <textarea v-else-if="type === 'textarea'" class="form__input" v-model="inputValue" :id="$.uid" :required="required" :autofocus="autofocus" :disabled="disabled" :placeholder="placeholder"></textarea>
    <input v-else-if="type === 'date'" class="form__input" type="date" v-model="inputValue" :id="$.uid" :required="required" :autofocus="autofocus" :disabled="disabled" :placeholder="placeholder" :min="min" :max="max">
    <slot v-else-if="type === 'custom'" name="custom-input" :id="$.uid" :required="required"></slot>
    <input v-else class="form__input" :type="type" v-model="inputValue" :id="$.uid" :required="required" :autofocus="autofocus" :disabled="disabled" :placeholder="placeholder" :readonly="readonly" :minlength="minlength" :maxlength="maxlength">
    <!-- <span class="form__input-info"></span> -->
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: [
    'modelValue',
    'type',
    'options', // : { type: Array, default () { return [] } } },
    'required', // : { type: Boolean, default () { return false } } },
    'autofocus', // : { type: Boolean, default () { return false } } },
    'disabled', // : { type: Boolean, default () { return false } } }
    'placeholder',
    'selectPrompt',
    'min',
    'max',
    'groupOptions',
    'minlength',
    'maxlength',
    'readonly'
  ],
  emits: ['update:modelValue'],
  computed: {
    inputValue: {
      get () { return this.modelValue },
      set (val) { this.$emit('update:modelValue', val) }
    }
  }
}
</script>

