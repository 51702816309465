export default {

  es: [
    { id: 'MULTIPLE-CHOICE-SINGLE-OPTION', name: 'Selección única', icon: require('@/assets/images/icons/ico-alt-quest--blue.svg') },
    { id: 'MULTIPLE-CHOICE-MULTIPLE-OPTION', name: 'Selección múltiple', icon: require('@/assets/images/icons/ico-check-quest--blue.svg') },
    { id: 'OPEN-ENDED', name: 'Abierta', icon: require('@/assets/images/icons/ico-open-quest--blue.svg') }
  ],

  en: [
    { id: 'MULTIPLE-CHOICE-SINGLE-OPTION', name: 'Unique choice', icon: require('@/assets/images/icons/ico-alt-quest--blue.svg') },
    { id: 'MULTIPLE-CHOICE-MULTIPLE-OPTION', name: 'Multiple choice', icon: require('@/assets/images/icons/ico-check-quest--blue.svg') },
    { id: 'OPEN-ENDED', name: 'Open', icon: require('@/assets/images/icons/ico-open-quest--blue.svg') }
  ]

}