<template>
  <div class="modal-backdrop">
    <div class="modal modal--large">
      <div @click.prevent="$emit('close')" class="modal__close-btn">
        <i class="fa fa-times"></i>
      </div>
      <div class="modal-container">
        <header>
          <h2><slot name="title"></slot></h2>
          <p class="subtitle"><slot name="description"></slot></p>
        </header>
        <section>
          <slot name="content"></slot>
        </section>
        <section class="form__actions">
          <slot name="footer"></slot>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal'
}
</script>
