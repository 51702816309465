<template>
    <ckeditor :editor="editor" v-model="editorValue" :config="config" ></ckeditor>
    <div id="word-count" class="word-num"></div>
</template>
<!-- @ready="onReady" -->
<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import Link from '@ckeditor/ckeditor5-link/src/link'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
// import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import List from '@ckeditor/ckeditor5-list/src/list'
// import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount';

  export default {
    name: 'ActivityObjectiveCkeditor',
    props: ['modelValue'],
    emits: ['update:modelValue'],
    components: {
      ckeditor: CKEditor.component,    
    },
    computed: {
      editorValue: {
        get () { return this.modelValue },
        set (val) { this.$emit('update:modelValue', val) },
      },
    },
    data () {
      return {
        editor: ClassicEditor,
        config: {
          plugins: [
            Link,
            Paragraph,
            // Alignment,
            List,
            // WordCount,
          ],
          toolbar: {
            items: [
              '|',
              'bulletedList',
              'numberedList',
              '|',
            ]
          },
        },
      }
    },
 
    methods: {
    },
  }
    
</script>
