<template>
  <form v-if="grades.length && subjects.length && curricularAxes.length" @submit.prevent="handleNext()">
    <template v-if="currentStep === 1 && $i18n.locale == 'es'" >
      <form-input v-model="myActivity.grade_id" type="select" :options="grades_es" selectPrompt="Seleccione" :required="true" :autofocus="true">
        {{$t('activitiesform.curso')}}
      </form-input>
      <form-input v-model="myActivity.subject_id" type="select" :options="subjects_es" selectPrompt="Seleccione" :disabled="true">
        {{$t('activitiesform.asignatura')}}
      </form-input>
      <form-input v-model="myActivity.curricular_axis_id" type="select" :options="curricularAxes_es" :required="true" selectPrompt="Seleccione">
        {{$t('activitiesform.eje')}}
      </form-input>
      <form-input v-model="myActivity.language" type="select" :options="language" selectPrompt="Seleccione" :required="true">
        {{$t('activitiesform.idioma')}}
      </form-input>
      <form-input type="custom" :required="false">
        <template #default>{{$t('activitiesform.objetivosDeAprendizaje')}}</template>
          <template #custom-input>
            <activity-objective-ckeditor v-model="myActivity.learning_objectives"/>
          </template>
      </form-input>
      <form-input v-model.trim="myActivity.socioemotional_development_objective" type="text" :autofocus="true" :required="false">
        {{$t('activitiesform.objetivoDeDesarrolloSocioemocional')}}
      </form-input>
      <form-input v-if="$store.state.user.role_id === 1" v-model="myActivity.is_default" type="select" :options="activityDefault" selectPrompt="Seleccione" :required="false">
        {{$t('activitiesform.actividadDefault')}}
      </form-input>
      <form-input type="radioGroup" :groupOptions="visibilityKinds" v-model="myActivity.is_public" :required="true">
        {{$t('activitiesform.visibilityactiv')}}
      </form-input>
    </template>

    
     <template v-else-if="currentStep === 1 && $i18n.locale == 'en'" >
      <form-input v-model="myActivity.grade_id" type="select" :options="grades_en" selectPrompt="Select" :required="true" :autofocus="true">
        {{$t('activitiesform.curso')}}
      </form-input>
      <form-input v-model="myActivity.subject_id" type="select" :options="subjects_en" selectPrompt="Select" :disabled="true">
        {{$t('activitiesform.asignatura')}}
      </form-input>
      <form-input v-model="myActivity.curricular_axis_id" type="select" :options="curricularAxes_en" :required="true" selectPrompt="Select">
        {{$t('activitiesform.eje')}}
      </form-input>
      <form-input v-model="myActivity.language" type="select" :options="language" selectPrompt="Select" :required="true">
        {{$t('activitiesform.idioma')}}
      </form-input>
      <form-input type="custom" :required="false">
        <template #default>{{$t('activitiesform.objetivosDeAprendizaje')}}</template>
          <template #custom-input >
            <activity-objective-ckeditor v-model="myActivity.learning_objectives"/>
          </template>
      </form-input>
      <form-input v-model.trim="myActivity.socioemotional_development_objective" type="text" :autofocus="true" :required="false">
        {{$t('activitiesform.objetivoDeDesarrolloSocioemocional')}}
      </form-input>
           <form-input v-if="$store.state.user.role_id === 1" v-model="myActivity.is_default" type="select" :options="activityDefaulten" :required="false" selectPrompt="Select">
        {{$t('activitiesform.actividadDefault')}}
      </form-input>
      <form-input type="radioGroup" :groupOptions="visibilityKindsen" v-model="myActivity.is_public" :required="true">
        {{$t('activitiesform.visibilityactiv')}}
      </form-input>
    </template>

    <template v-if="currentStep === 2">
      <form-input v-model.trim="myActivity.title" type="text" :autofocus="true" :required="true">
        {{$t('activitiesform.title')}}
      </form-input>
      <div class="type-creation">
        <h5 class="texto-herramienta">{{$t('activitiesform.herramientacreacion')}}</h5>
          <div class="buttons">
            <button  class="btn-herramienta" :class="{'btn-active':!external_creation, 'btn-herramienta': external_creation}" type="button" @click="typeCreation(false),resetForm(false)" >
              {{$t('activitiesform.manual')}}
            </button>
            <button class="btn-herramienta" :class="{'btn-active':external_creation, 'btn-herramienta': !external_creation}" type="button" @click="typeCreation(true),resetForm(true)" >
              {{$t('activitiesform.genially')}}
            </button>
          </div>
      </div>
      <form-input v-if="external_creation == false" type="custom" :required="true">
        <template #default>{{$t('activitiesform.Instruccion')}}</template>
        <template #custom-input>
          <p class="caption">{{$t('activitiesform.caption1')}}
            {{$t('activitiesform.caption2')}}
          </p>
          <activity-instruction-ckeditor v-model="myActivity.instruction"/><button class="btn btn--primary" @click.prevent="Slide()">{{$t('activitiesform.addslide')}}</button>
        </template>
      </form-input>

      <form-input v-else v-model.trim="myActivity.instruction" type="textarea">
        {{$t('activitiesform.linkgenially')}}
      </form-input>
      <form-input v-model.trim="myActivity.external_resource_url_print" type="text">
        {{$t('activitiesform.enlacePrint')}}
      </form-input>
      <form-input v-model.trim="myActivity.external_resource_url_recom" type="text">
        {{$t('activitiesform.enlaceRecom')}}
      </form-input>
      <!--<form-input type="file" name="pdf" @change="uploadFile">
        {{$t('activitiesform.enlaceRecom')}}
      </form-input>
      <button @click.prevent="upload()">subir</button>-->
      <form-input v-model.trim="myActivity.guia_docente_interactiva" type="textarea" placeholder="i-frame Genially">
        {{$t('activitiesform.enlaceInteractiva')}}
      </form-input>
      <form-input v-model.trim="myActivity.external_resource_url_apod" type="text">
        {{$t('activitiesform.enlaceApod')}}
      </form-input>
    </template>

    <template v-if="currentStep === 3">
      <div class="grid grid-2">
        <section class="main-section">
          <h3 class="main-section__title">{{$t('activitiesform.questionaddactivity')}}</h3>
          <div class="box box--sm box--no-shadow" v-for="(question, index) in myActivity.questions" :key="question.id">
            <div class="question-list question-list--sm">
              <question-card :question="question"/>
              <button class="btn btn--circle btn--danger btn--xs" type="button" @click.prevent="removeQuestionFromActivityAt(index)">
                <img class="icon" src="@/assets/images/icons/ico-close--white.svg">
              </button>
            </div>
          </div>
          <div class="box box--sm box--no-shadow" v-if="showNewQuestionForm">
            <questions-form :inModal="true" @submit-question="persistQuestion($event)"/>
            <div class="form__actions">
              <button  type="button" class="btn btn--primary btn--outline" @click.prevent="triggerSaveQuestion()">{{$t('activitiesform.addquestion')}}</button>
            </div>
          </div>
          <div v-else class="form__actions">
            <button class="btn btn--primary btn--circle btn--sm" @click.prevent="showNewQuestionForm = true">
              <img class="icon" src="@/assets/images/icons/ico-add--white.svg">
            </button>
          </div>
        </section>
        <section class="main-section">
          <h3 class="main-section__title">{{$t('activitiesform.questionbank')}}</h3>
          <form @submit.prevent="searchQuestions({ performSearchAlways: true })">
            <form-input v-if="$i18n.locale == 'es'" v-model.trim="searchTerm" type="search" :autofocus="true" placeholder="Buscar" @input="searchQuestions({ performSearchAlways: false })"></form-input>
            <form-input v-else-if="$i18n.locale == 'en'" v-model.trim="searchTerm" type="search" :autofocus="true" placeholder="Search" @input="searchQuestions({ performSearchAlways: false })"></form-input>
          </form>
          <div v-if="questions.length" class="box box--sm box--no-shadow">
            <div class="question-list question-list--sm" v-for="question in questions" :key="question.id">
              <question-card :question="question"/>
              <div class="question-add">
                <button type="button" @click.prevent="addQuestionToActivity(question)" class="btn btn--primary btn--outline btn--xs">{{$t('activitiesform.btnadd')}}</button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </template>

    <!-- Solucion momentanea más triste del mundo :() -->
    <br><br><br><br>

    <div class="form__actions form__actions--fixed">
      <div class="form__actions-side">
        <div class="form__actions-steps">
          <div v-for="n in steps" :key="n" :class="['form__actions-step', { 'form__actions-step--active': n === currentStep }]">
            {{n}}
          </div>
        </div>
      </div>
      <div class="form__actions-side">
        <button class="btn btn--primary" type="submit" :disabled="submitDisabled">
         
          <slot v-if="currentStep !== steps" name="nextButton">{{$t('activitiesform.btncontinuar')}}</slot>
          <slot v-else name="finishButton"></slot>
        </button>
      </div>
    </div>
  </form>
  <modal v-if="this.showActivationModal">
    <template #title>Subiendo Archivo</template>
    <template #content>
      
    <div style="width: 100%;">
    <div style="position: relative; padding-bottom: 56.25%; padding-top: 0; height: 0;">
      hola
    
      </div> 
    </div>
    </template>
    <template #footer>
      <button type="button" class="btn btn--primary btn--outline" @click.prevent="cancelShowPdfUploading()" :disabled="submitting">cerrar</button>
    </template>
    </modal>
</template>

<script>
import { inject, reactive} from 'vue'
import eventHub from '@/eventHub'
import FormInput from '@/components/FormInput'
import ActivityInstructionCkeditor from '@/components/ActivityInstructionCkeditor'
import ActivityObjectiveCkeditor from '@/components/ActivityObjectiveCkeditor'
import QuestionsForm from '@/components/QuestionsForm'
import QuestionCard from '@/components/QuestionCard'
import { useRouter } from 'vue-router'
import Modal from '@/components/Modal'

export default {
  name: 'ActivitiesForm',
  emits: ['submit-activity'],
  components: { FormInput, ActivityInstructionCkeditor, ActivityObjectiveCkeditor, QuestionsForm, QuestionCard, Modal },
  data () {
    return {
      submitting: false,
      currentStep: 1,
      steps: 3,
      showNewQuestionForm: false,
      visibilityKinds: [
        { id: true, name: 'Pública' },
        { id: false, name: 'Privada' }
      ],
       visibilityKindsen: [
        { id: true, name: 'Public' },
        { id: false, name: 'Private' }
      ],
      language: [
        { id: 'Es', name: 'Español' },
        { id: 'En', name: 'English' }
      ],
      searchTerm: '',
      external_creation: false,
      route:useRouter().currentRoute.value,
      pdf:null,
      uploadingPdf:false,
      showActivationModal: false,
      placeholder: {
      type: String,
      default: 'Genially'
    },
      activityDefault: [
        { id: true, name: 'Sí' },
        { id: false, name: 'No' }
      ],
       activityDefaulten: [
        { id: true, name: 'Yes' },
        { id: false, name: 'No' }
      ],
      
    }
  },
  props: ['activity'],
  computed: {
    questions: {
      get () {
        const selectedQuestionIds = this.myActivity.questions.map(q => q.id)
        return this.myQuestions.filter(question => !selectedQuestionIds.includes(question.id))
        
      },
      set (val) {
        this.myQuestions.splice(0)
        this.myQuestions.push(...val)
      }
    },
    submitDisabled () {
      let disabled
      switch (this.currentStep) {
        case 1:
          disabled = !(this.myActivity.grade_id && this.myActivity.subject_id && 
          this.myActivity.curricular_axis_id) || this.myActivity.is_public === null
          break
        case 2:
          disabled = !(this.myActivity.title && this.myActivity.instruction)
          break
        case 3:
          disabled = false
          break

        default:
          disabled = true
          break
      }
      return disabled || this.submitting
    }
  },
  methods: {
    async searchQuestions ({ performSearchAlways }) {
      if (this.searchTerm && !performSearchAlways) return
      this.questions = (await this.axios.get('questions/for-new-activity', { params: { searchTerm: this.searchTerm } })).data.questions
    },
    addQuestionToActivity (question) {
      this.myActivity.questions.push(question)
    },
    removeQuestionFromActivityAt (index) {
      this.myActivity.questions = this.myActivity.questions.filter((q, i) => i !== index)
    },
    triggerSaveQuestion () {
      eventHub.$emit('submit-question')
    },
    async persistQuestion (question) {
      question.is_public = this.$store.state.user.role_id === 1
      const newQuestion = (await this.axios.post('questions', { question })).data.question
      this.myQuestions.push(newQuestion)
      this.myActivity.questions.push(newQuestion)
      this.showNewQuestionForm = false
    },
    handleNext () {
      if (this.currentStep < this.steps) {
        this.currentStep++
      } else {
        this.submitting = true
        this.$emit('submit-activity', this.$utils.cloneObj(this.myActivity))
      }
    },
    Slide() {
    this.myActivity.instruction =  this.myActivity.instruction + "---slide divider---"
    },
    typeCreation(type){
      if(type == false){
        this.external_creation = false

      }else{
        this.external_creation = true
      }
    },
    resetForm(type){
      if(this.route.name == "ActivitiesNew"){
        if(type == false){
          this.myActivity.instruction = null
        }else{
        this.myActivity.instruction = null
        }
      }
    },
      // uploadFile(event) {
      //   this.pdf = event.target.files[0]
      //   if(this.pdf){
      //   this.myActivity.external_resource_url_recom = this.pdf.name
      //   }

      // },
    // async upload(){
    //   const formData = new FormData();
    //   formData.append('pdf', this.pdf);
    //   this.uploadingPdf = true
    //   this.showActivationModal = true
    //   const response = await this.axios.post('upload-aws/upload',formData)
    //   if(response.data.message){
    //     this.uploadingPdf = false
    //   }
    // },
    // cancelShowPdfUploading(){
    //   if(this.showActivationModal && !this.uploadingPdf){
    //     console.log(this.showActivationModal && !this.uploadingPdf)
    //     this.showActivationModal = false
    //   }else{
    //     this.showActivationModal = true
    // }
    // }
  },

  setup (props) {
    
    const axios = inject('$axios')
    const cloneObj = inject('$utils').cloneObj
    const output = {
      axios,
      grades: reactive([]),
      grades_en: reactive([]),
      grades_es: reactive([]),
      subjects: reactive([]),
      subjects_es: reactive([]),
      subjects_en: reactive([]),
      curricularAxes: reactive([]),
      curricularAxes_es: reactive([]),
      curricularAxes_en: reactive([]),
      myQuestions: reactive([]),
      myActivity: reactive({
        grade_id: null,
        subject_id: 1,
        title: null,
        learning_objectives: null,
        socioemotional_development_objective: null,
        curricular_axis_id: null,
        is_public: null,
        language: null,
        instruction: null,
        external_resource_url_print: null,
        external_resource_url_recom: null,
        external_resource_url_apod: null,
        guia_docente_interactiva: null,
        is_default:null,
     
        questions: []
        
      }),
    }
    if (props.activity) {
      output.myActivity = reactive(cloneObj(props.activity))
    }
    axios.get('grades').then(res => res.data.grades.forEach(grade => output.grades.push(grade))) 
    axios.get('grades-en').then(res => res.data.grades_en.forEach(grade => output.grades_en.push(grade)))
    axios.get('grades-es').then(res => res.data.grades_es.forEach(grade => output.grades_es.push(grade)))
    axios.get('subjects').then(res => res.data.subjects.forEach(subject => output.subjects.push(subject)))
    axios.get('subjects-es').then(res => res.data.subjects_es.forEach(subject => output.subjects_es.push(subject)))
    axios.get('subjects-en').then(res => res.data.subjects_en.forEach(subject => output.subjects_en.push(subject)))
    axios.get('curricular-axes').then(res => res.data.curricular_axes.forEach(axis => output.curricularAxes.push(axis)))
    axios.get('curricular-axes-es').then(res => res.data.curricular_axes_es.forEach(axis => output.curricularAxes_es.push(axis)))
    axios.get('curricular-axes-en').then(res => res.data.curricular_axes_en.forEach(axis => output.curricularAxes_en.push(axis)))
    axios.get('questions/for-new-activity').then(res => res.data.questions.forEach(question => output.myQuestions.push(question)))
    return output
    
    
  }
}
</script>

<style>

.buttons{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.texto-herramienta{
    display: block;
    font-family: "RobotoCondensed-Bold";
    font-size: var(--caption-xs);
    text-transform: uppercase;
    line-height: var(--caption);
    margin-bottom: var(--space-xs);
    color: var(--color-text);
    margin-left: var(--space-xs);
}
.btn-herramienta{
    width: auto;
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: var(--color-white);
    height: 45px;
    padding: var(--space-no) var(--space-l);
    border: 1px solid var(--color-gray-03);
    border-radius: var(--radius);
    font-family: "RobotoCondensed-Bold";
    font-size: var(--caption);
    text-transform: uppercase;
    letter-spacing: 1px;
    white-space: nowrap;
    cursor: pointer;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    margin-right:0.5rem;
}
.btn-active{
    width: auto;
    background-color: var(--color-primary);
    color: var(--color-white);
    height: 45px;
    padding: var(--space-no) var(--space-l);
    border: 1px solid var(--color-tertiary--95);
    border-color: var(--color-secondary);
    border-radius: var(--radius);
    border-style: outset;
    font-family: "RobotoCondensed-Bold";
    font-size: var(--caption);
    text-transform: uppercase;
    letter-spacing: 1px;
    white-space: nowrap;
    cursor: pointer;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    margin-right:0.5rem;
    box-shadow: 1px 1px 1px 1px rgba(249, 173, 31, 0.5);
}

</style>
