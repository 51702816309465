<template>
  <form @submit.prevent="handleNext()">
    <form-input type="text" v-model.trim="myQuestion.stem" :required="true" :autofocus="true">
      {{$t('questionform.encabezado')}}
    </form-input>

    <form-input v-if="$i18n.locale == 'es'" type="radioGroup" :groupOptions="questionKinds.es" v-model="myQuestion.kind">
      {{$t('questionform.responsetype')}}
    </form-input>


    <form-input v-else-if="$i18n.locale == 'en'" type="radioGroup" :groupOptions="questionKinds.en" v-model="myQuestion.kind">
      {{$t('questionform.responsetype')}}
    </form-input>

    <template v-if="hasAlternatives">
      <template v-for="n in myQuestion.alternatives.length" :key="n">
        <div class="input-remotion">
          <form-input type="text" v-model.trim="myQuestion.alternatives[n - 1]" :required="true">
            {{$t('questionform.Opcion')}}
          </form-input>
          <button class="btn btn--circle btn--danger btn--xs" v-if="myQuestion.alternatives.length > 2" type="button" @click.prevent="removeAlternativeAt(n - 1)">
            <img class="icon" src="@/assets/images/icons/ico-close--white.svg">
          </button>
        </div>
      </template>
      <div class="form__actions form__actions--xs">
        <button type="button" class="btn btn--primary btn--circle btn--sm" @click.prevent="addAlternative()">
          <img class="icon" src="@/assets/images/icons/ico-add--white.svg">
        </button>
      </div>
    </template>

    <div v-show="!inModal">
      <!-- Solucion momentanea más triste del mundo :( -->
      <br><br><br><br>

      <div class="form__actions form__actions--fixed">
        <div class="form__actions-side"></div>
        <div class="form__actions-side">
          <button class="btn btn--primary" type="submit" ref="submitButton" :disabled="!myQuestion.kind">
            <slot name="finishButton"></slot>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { computed, inject, reactive, toRefs, watchEffect } from 'vue'
import eventHub from '@/eventHub'
import FormInput from '@/components/FormInput'
import questionKinds from '@/utils/questionKinds'

export default {
  name: 'ActivitiesForm',
  components: { FormInput },
  data () {
    return { questionKinds }
  },
  props: ['question', 'inModal'],
  methods: {
    handleNext () {
      this.$emit('submit-question', this.$utils.cloneObj(this.myQuestion))
    },
    addAlternative () {
      this.myQuestion.alternatives.push('')
    },
    removeAlternativeAt (index) {
      this.myQuestion.alternatives = this.myQuestion.alternatives.filter((alternative, i) => i !== index)
    }
  },
  mounted () {
    eventHub.$on('submit-question', () => {
      this.$refs.submitButton.click()
    })
  },
  beforeUnmount () {
    eventHub.$off('submit-question')
  },
  setup (props) {
    const cloneObj = inject('$utils').cloneObj

    const output = reactive({
      myQuestion: {
        stem: null,
        kind: null,
        alternatives: []
      },
      hasAlternatives: computed(() => {
        return ['MULTIPLE-CHOICE-SINGLE-OPTION', 'MULTIPLE-CHOICE-MULTIPLE-OPTION'].includes(output.myQuestion.kind)
      })
    })

    watchEffect(() => {
      if (output.hasAlternatives) {
        output.myQuestion.alternatives = ['', '']
      } else {
        output.myQuestion.alternatives = []
      }
    })

    if (props.question) {
      output.myQuestion = cloneObj(props.question)
    }
    return { ...toRefs(output) }
  }
}
</script>
